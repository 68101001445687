import React from 'react';
import { personalData } from '../utils/data/personal-data';
import { BsGithub, BsLinkedin } from 'react-icons/bs';
import { MdDownload } from 'react-icons/md';
import { RiContactsFill } from 'react-icons/ri';
import heroImage from '../assets/hero.svg';

const HeroSection = () => {
  return (
    <section className="relative flex flex-col items-center justify-between py-4 lg:py-12">
      <img
        src={heroImage}
        alt="Hero"
        width={1572}
        height={795}
        className="absolute -top-[98px] -z-10"
      />
      <div className="grid grid-cols-1 items-start lg:grid-cols-2 lg:gap-12 gap-y-8">
        <div className="order-2 lg:order-1 flex flex-col items-start justify-center p-2 pb-20 md:pb-10 lg:pt-10">
          <h1 className="text-3xl font-bold leading-10 text-white md:font-extrabold lg:text-[2.6rem] lg:leading-[3.5rem]">
            <br />
            I am <span className="text-pink-500">{personalData.name}</span>
            <br />
            <span className="text-[#16f2b3]">{personalData.designation}</span>.
          </h1>
          <div className="my-12 flex items-center gap-5">
            <a href={personalData.github} target='_blank' className="transition-all text-pink-500 hover:scale-125 duration-300">
              <BsGithub size={30} />
            </a>
            <a href={personalData.linkedIn} target='_blank' className="transition-all text-pink-500 hover:scale-125 duration-300">
              <BsLinkedin size={30} />
            </a>
          </div>
          <div className="flex items-center gap-3">
            <a href="#about" className="bg-gradient-to-r to-pink-500 from-violet-600 p-[1px] rounded-full transition-all duration-300 hover:from-pink-500 hover:to-violet-600">
              <button className="px-3 text-xs md:px-8 py-3 md:py-4 bg-[#0d1224] rounded-full border-none text-center md:text-sm font-medium uppercase tracking-wider text-[#ffff] no-underline transition-all duration-200 ease-out md:font-semibold flex items-center gap-1 hover:gap-3">
                <span>About me</span>
                <RiContactsFill size={16} />
              </button>
            </a>
            <a className="flex items-center gap-1 hover:gap-3 rounded-full bg-gradient-to-r from-pink-500 to-violet-600 px-3 md:px-8 py-3 md:py-4 text-center text-xs md:text-sm font-medium uppercase tracking-wider text-white no-underline transition-all duration-200 ease-out hover:text-white hover:no-underline md:font-semibold" role="button" target="_blank" href={personalData.resume}>
              <span>Get Resume</span>
              <MdDownload size={16} />
            </a>
          </div>
        </div>
        <div className="order-3 lg:order-2 from-[#0d1224] border-[#1b2c68a0] relative rounded-lg border bg-gradient-to-r to-[#0a0d37]">
          <div className="flex flex-row">
            <div className="h-[1px] w-full bg-gradient-to-r from-transparent via-pink-500 to-violet-600"></div>
            <div className="h-[1px] w-full bg-gradient-to-r from-violet-600 to-transparent"></div>
          </div>
          <div className="px-4 lg:px-8 py-5">
            <div className="flex flex-row space-x-2">
              <div className="h-3 w-3 rounded-full bg-red-400"></div>
              <div className="h-3 w-3 rounded-full bg-orange-400"></div>
              <div className="h-3 w-3 rounded-full bg-green-200" style={{ backgroundColor: 'green' }}></div>
            </div>
          </div>
          <div className="overflow-hidden border-t-[2px] border-indigo-900 px-4 lg:px-8 py-4 lg:py-8">
            <code className="font-mono text-xs md:text-sm lg:text-base">
              <div className="blink">
                <span className="mr-2 text-white">Product Hacker</span>
                <span className="mr-2 text-pink-500">=</span>
                <span className="text-gray-400">{'{'}</span>
              </div>
              <div>
                <span className="ml-4 lg:ml-8 mr-2 text-white">Name:</span>
                <span className="text-gray-400">{`'`}</span>
                <span className="text-green-300">Yahya Ghani</span>
                <span className="text-gray-400">{`',`}</span>
              </div>
             
              <div className="ml-4 lg:ml-8 mr-2">
                <span className="text-white">Dev:</span>
                <span className="text-gray-400">{` ['`}</span>
                <span className="text-green-300">Python (Flask/Django)</span>
                <span className="text-gray-400">{"', '"}</span>
                <span className="text-green-300">React (TS/Native)</span>
                <span className="text-gray-400">{"', '"}</span>
                <span className="text-green-300">PHP</span>
                <span className="text-gray-400">{"'],"}</span>
              </div>
              <div className="ml-4 lg:ml-8 mr-2">
                <span className="text-white">ML:</span>
                <span className="text-gray-400">{` ['`}</span>
                <span className="text-green-300">Tensorflow</span>
                <span className="text-gray-400">{"', '"}</span>
                <span className="text-green-300">Torch</span>
                <span className="text-gray-400">{"', '"}</span>
                <span className="text-green-300">Keras</span>
                <span className="text-gray-400">{"', '"}</span>
                <span className="text-green-300">Spacy</span>
                <span className="text-gray-400">{"', '"}</span>
                <span className="text-green-300">NLTK</span>
                <span className="text-gray-400">{"', '"}</span>
                <span className="text-green-300">OpenCV</span>
                <span className="text-gray-400">{"', '"}</span>
                <span className="text-green-300">Sci-kit</span>
                <span className="text-gray-400">{"', '"}</span>
                <span className="text-amber-300">CUDA</span>
                <span className="text-gray-400">{"'],"}</span>
              </div>
              <div className="ml-4 lg:ml-8 mr-2">
                <span className="text-white">LLMOPS:</span>
                <span className="text-gray-400">{` ['`}</span>
                <span className="text-green-300">GPT2+Finetune</span>
                <span className="text-gray-400">{"', '"}</span>
                <span className="text-green-300">RAGOps</span>
                <span className="text-gray-400">{"', '"}</span>
                <span className="text-green-300">LangChain</span>
                <span className="text-gray-400">{"', '"}</span>
                <span className="text-green-300">LlamaIndex</span>
                <span className="text-gray-400">{"', '"}</span>
                <span className="text-amber-300">W&B</span>
                <span className="text-gray-400">{"'],"}</span>
              </div>
              <div className="ml-4 lg:ml-8 mr-2">
                <span className="text-white">Infra:</span>
                <span className="text-gray-400">{` ['`}</span>
                <span className="text-green-300">SQL</span>
                <span className="text-gray-400">{"', '"}</span>
                <span className="text-green-300">NoSql</span>
                <span className="text-gray-400">{"', '"}</span>
                <span className="text-amber-300">GraphQl</span>
                <span className="text-gray-400">{"', '"}</span>
                <span className="text-green-300">GCP</span>
                <span className="text-gray-400">{"', '"}</span>
                <span className="text-amber-300">AWS</span>
                <span className="text-gray-400">{"', '"}</span>
                <span className="text-amber-300">Kubernetes</span>
                <span className="text-gray-400">{"', '"}</span>
                <span className="text-green-300">Docker</span>
                <span className="text-gray-400">{"', '"}</span>
                <span className="text-green-300">Celery</span>
                <span className="text-gray-400">{"', '"}</span>
                <span className="text-green-300">Socket</span>
                <span className="text-gray-400">{"'],"}</span>
              </div>
              <div className="ml-4 lg:ml-8 mr-2">
                <span className="text-white">Misc:</span>
                <span className="text-gray-400">{` ['`}</span>
                <span className="text-green-300">Linux</span>
                <span className="text-gray-400">{"', '"}</span>
                <span className="text-green-300">Gimp</span>
                <span className="text-gray-400">{"', '"}</span>
                <span className="text-amber-300">Blender</span>
                <span className="text-gray-400">{"', '"}</span>
                <span className="text-amber-300">Unity</span>
                <span className="text-gray-400">{"', '"}</span>
                <span className="text-amber-300">Figma</span>
                <span className="text-gray-400">{"'],"}</span>
              </div>
              <div className="ml-4 lg:ml-8 mr-2">
                <span className="text-white">Papers:</span>
                <span className="text-gray-400">{` ['`}</span>
                <span className="text-green-300">ReAct</span>
                <span className="text-gray-400">{"', '"}</span>
                <span className="text-amber-300">AIAYN</span>
                <span className="text-gray-400">{"', '"}</span>
                <span className="text-green-300">BERT</span>
                <span className="text-gray-400">{"', '"}</span>
                <span className="text-amber-300">CLIP</span>
                <span className="text-gray-400">{"', '"}</span>
                <span className="text-green-300">Swin</span>
                <span className="text-gray-400">{"', '"}</span>
                <span className="text-amber-300">TableNet</span>
                <span className="text-gray-400">{"', '"}</span>
                <span className="text-green-300">GPT</span>
                <span className="text-gray-400">{"', '"}</span>
                <span className="text-green-300">MobileNetV3</span>
                <span className="text-gray-400">{"'],"}</span>
              </div>
              <div><span className="text-gray-400">{`};`}</span></div>
            </code>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
